import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ExecuteTestCycle = () => {
  return (
    <LayoutComponent>
      <h1>Executing a Test Cycle</h1>

      <p>
        To begin executing a test cycle, choose one of the following options:
      </p>
      <ul>
        <li>
          Click the <strong>Play</strong> button in the Test Cycles table next
          to the desired cycle.
        </li>
        <li>
          Alternatively, go to the Test Cases tab on the Test Cycle page and
          click the <strong>Play</strong> icon.
        </li>
      </ul>
      <p>
        Both options will launch the Test Player, where you can manage and track
        your test executions.
      </p>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          In the Test Player, you can either start a new iteration or continue
          existing executions. You can also view executions by iteration or by
          test case to better manage your testing process.
        </p>
      </div>

      <h5>Using the Test Player</h5>
      <p>
        The Test Player is your central hub for conducting and monitoring tests.
        Start the execution by clicking the <strong>Play</strong> button, which
        activates the test-execution timer and begins your testing session.
      </p>

      <h5>Starting a New Iteration</h5>
      <p>
        To start a new iteration, click the{" "}
        <strong>Start New Iteration </strong>
        button within the Test Player. A pop-up will appear allowing you to
        select the environment and the test cases for this iteration.
      </p>

      <h5>Update and Track Your Progress</h5>
      <p>
        As you execute tests, use the Test Player to update the status of each
        test case. You can mark test steps as <strong>Pass</strong>,{" "}
        <strong>Fail</strong>, or <strong>Blocked</strong> to track your
        progress. Update the status of each test step by clicking on the status
        dropdown.
      </p>
    </LayoutComponent>
  )
}

export default ExecuteTestCycle
